import React, {useEffect, useState} from 'react';
import useTranslations from "../../utils/useTranslations"
import ComparisonPanels from "../../components/comparisonPanels"
import LocalizedLink from "../../components/localizedLink"
import Logo from "../../components/logo"
import backgrounds from "../../components/backgrounds"

const BittorrentWebCompare = ({ pageContext: {text, pageName, locale} }) => {
const t = useTranslations(text)

const [isMac, setIsMac] = useState(false);

useEffect(() => {
	setIsMac(window.gatsbyIsMac)
}, [])

const logoTitle = <>
	<div className="text-primary font-weight-normal text-nowrap">Compare <div className="compare-page-logo-container"><Logo secondaryColor="black" tag="p" productName="Web" fsCoefficient="15.5" className="compare-page-logo"/></div> Versions</div>
</>
const titleContent = (locale === "en") ? logoTitle : t("Compare μTorrent Web Versions")


  return (
  	<div className="container-fluid py-5 px-0 mx-0 mt-5" style={backgrounds.lightBg}>
  	<div className="container-fluid panel-bg">
	    <div className="container">
	      <h1 className="h2 text-center">{titleContent}</h1>
	      <ComparisonPanels pageContext={{ "text":text, "pageName":pageName, isMac }}>
	      	{
	      	  !isMac &&
		      <p className="text-center mt-5">
		      	<LocalizedLink to="/web/pro/" id="products-win-btweb-compare-premium-learnmore" className="font-weight-bold">
		      		{t(`Learn more`)}
		      	</LocalizedLink> {t(`about µTorrent Web premium products`)}
		      </p>
      		}
		  </ComparisonPanels>
	    </div>
  	</div>
  	</div>
  )
}

export default BittorrentWebCompare