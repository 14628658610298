import React from "react";



const backgrounds = {
  lightBg:{
    backgroundImage:`linear-gradient(52deg, rgba(196,196,201,0.25) 1%, rgba(247,247,247,1) 53%, rgba(196,196,201,0.25) 99%)`,
    backgroundSize:`cover`
  },
  darkBg:{
    backgroundImage:`radial-gradient(circle at 50% -30%, #242433, #242433 60%)`,
    backgroundSize:`cover`
  },
  darkBg2: {
    backgroundImage: `linear-gradient(to bottom, #39394f, #1d1d2a 92%)`,
    backgroundSize:`cover`
  },
  darkBg3: {
    backgroundImage: `linear-gradient(to top, #39394f, #1d1d2a 92%)`,
    backgroundSize:`cover`
  },
  
}

export default backgrounds;